.App{
  margin:auto;
}
body{
  color:white;
  max-width:100vw;
  overflow-x:hidden;
  min-height:100%;
}

*{
  color:white;
  box-sizing:border-box;
}

.row{
  display:flex;
  flex-wrap:wrap;
}

.coverArt{
  width:100%;
  border-radius:10px;
  box-shadow:2px 6px 7px -4px #000000
}
a{
  text-decoration: none;
}
.mainContent{
  margin-top:20px;
  position:relative;
  z-index:50;
  a{
		text-decoration: underline;
	}
}
body *{
  font-family: 'Fredoka One', cursive;
}

p{
  margin:0;
  font-family: 'Fredoka One', cursive;
  font-size:1.9rem;
  overflow: initial;
}

h1{
  font-family: 'Fredoka One', cursive;
  font-size:4rem;
  margin:0;
}

.links p{
  font-size:1.5rem;
}
.nav a{
  font-size:1.5rem;
  margin-right:40px;
}
.nav h1{
  font-size:2rem;
}
.nav-column{
  display: flex;
  align-items:center;
  justify-content: space-between;
}
.prompt{
  text-align:center;
  margin-bottom:40px;
}
form{
  display: flex;
  flex-direction:column;
}
body {
	background: linear-gradient(-45deg,   #75aee4, #81c4ff,#336fe9, #6194fb);
	background-size: 400% 400%;
	animation: gradient 25s ease infinite;
	height: 100vh;
  padding-bottom:40px;
}
.row .spotify-player{
  margin-top:20px;
  margin-bottom:20px;
}
.links{
	a{
		text-decoration: underline;
	}
}

body{
  .ease-in{
	  opacity:1;
	  transform:translate3d(0,0,0);
  }
  .offset-1{
    transition-delay: .3s;
  }
  .offset-2{
    transition-delay: .6s;
  }
  .offset-3{
    transition-delay: 1s;
  }
}
.ease{
  opacity:0;
  transform:translate3d(0,-10px,0);
  transition:opacity .5s ease, transform .5s ease;
}

input, textarea{
  padding:20px;
  width:100%;
  color:black;
  margin-top:5px;
  border:none;
  border-radius:10px;
}
form{
  input,textarea{
    margin-bottom:20px;
  }
  h2{
    text-align:center;
  }
}
.submit-button{
  margin-top:20px;
  padding:20px;
  border-radius:20px;
  background-color:transparent;
  border:none;
  border:5px solid #fcd040;
  cursor:pointer;
  font-size:1.5rem;
  transition:background-color .2s ease;
  display: flex;
  justify-content:center;
  &:hover{
    background-color:#fcd040;
  }
}
.gameSearch{
  display: flex;
  flex-direction:column;
  align-items:center;
}

body{
	overflow:hidden;
}

body.scroller{
  overflow: scroll;
}

.gallery{
  width:100%;
  display: flex;
  flex-wrap:wrap;
  margin-top:40px;
  .gallery-item{
    width:25%;
    position:relative;
    display: flex;
    align-items:center;
    padding:10px;
    justify-content: center;
    &:hover{
      img{
        filter: brightness(0.1)
      }
      p{
        opacity:1;
      }
    }
    p{
      font-size:1.5rem;
      line-height:1.9rem;
      position:absolute;
      max-width:75%;
      opacity:0;
      transition:opacity .3s ease;
    }
    img{
      max-width:100%;
      width:100%;
      transition:filter .3s ease;
      border-radius:10px;
    }
  }
}

.episodeArtGallery{
  text-align: center;
  h2{
    font-size:3rem;
  }
}

@media only screen and (min-width: 33.75em) and (max-width:1199px){
  .container {
    width: 90%;
  }

  .nav a{
    font-size: 1.1rem;
    margin-right:20px;
  }
}


@media screen and (max-width: 1200px) {
  body {
    max-height:auto;
    overflow: scroll;
    height:auto;
    background-size: 400% 500%;
  }
  .col-6{
    width:90%;
  }
  .links{
    margin-top:0;
  }
  .mainContent{
    margin-top:10px;
  }
  #particle-container{
    position:absolute;
    top:0;
  }
  .gameSearch{
    padding-bottom:40px;
  }
  .gallery{
    .gallery-item{
      width:33%;
    }
  }
}

#particle-container{
  overflow:initial;
  z-index: -1;
}


@keyframes fadeIn {
  from { 
		opacity: 0; 
		transform:translate3d(0, -10px, 0);
	}
  to { 
		opacity: 1;
		transform:translate3d(0,0,0);
	}
}

@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

.particle {
	position: absolute;
 	border-radius: 50%;
   z-index:-1;
}

@for $i from 1 through 30 {
	@keyframes particle-animation-#{$i} {
		100% {
			transform: translate3d((random(90) * 1vw), (random(90) * 1vh), (random(100) * 1px));
		}
	}
	
	.particle:nth-child(#{$i}){
		animation: particle-animation-#{$i} 60s infinite;
		$size: random(5) + 5 + px;
		opacity: calc(random(100) / 100);
		height: $size;
		width: $size;
		animation-delay: -$i * .2s;
		transform: translate3d((random(90) * 1vw), (random(90) * 1vh), (random(100) * 1px));
		background: rgb(145,170,222);
	}
}

.sk-chase {
  width: 40px;
  height: 40px;
  position: relative;
  animation: sk-chase 2.5s infinite linear both;
  overflow:initial;
}

.sk-chase-dot {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0; 
  animation: sk-chase-dot 2.0s infinite ease-in-out both; 
}

.sk-chase-dot:before {
  content: '';
  display: block;
  width: 25%;
  height: 25%;
  background-color: #fff;
  border-radius: 100%;
  animation: sk-chase-dot-before 2.0s infinite ease-in-out both; 
}

.sk-chase-dot:nth-child(1) { animation-delay: -1.1s; }
.sk-chase-dot:nth-child(2) { animation-delay: -1.0s; }
.sk-chase-dot:nth-child(3) { animation-delay: -0.9s; }
.sk-chase-dot:nth-child(4) { animation-delay: -0.8s; }
.sk-chase-dot:nth-child(5) { animation-delay: -0.7s; }
.sk-chase-dot:nth-child(6) { animation-delay: -0.6s; }
.sk-chase-dot:nth-child(1):before { animation-delay: -1.1s; }
.sk-chase-dot:nth-child(2):before { animation-delay: -1.0s; }
.sk-chase-dot:nth-child(3):before { animation-delay: -0.9s; }
.sk-chase-dot:nth-child(4):before { animation-delay: -0.8s; }
.sk-chase-dot:nth-child(5):before { animation-delay: -0.7s; }
.sk-chase-dot:nth-child(6):before { animation-delay: -0.6s; }

@keyframes sk-chase {
  100% { transform: rotate(360deg); } 
}

@keyframes sk-chase-dot {
  80%, 100% { transform: rotate(360deg); } 
}

@keyframes sk-chase-dot-before {
  50% {
    transform: scale(0.4); 
  } 100%, 0% {
    transform: scale(1.0); 
  } 
}

.mobile{
  display:none;
}

.homepage {
  h1{
    font-size:3.3em;
  }
  p{
    font-size:1.6em;
    @media (max-width: 801px){
      font-size:1.4em;
    }
  }
}

.icon-list{
  display:flex;
  align-items:center;
  justify-content: center;
}

.icon{
  margin-right:20px;
  img{
    width:40px !important;
  }
}

@media (max-width:801px)  {
  .desktop{
    display:none;
  }
  .mobile{
    display: initial;
  }
  p{
    font-size:1.4rem;
    line-height:2rem;
  }
  .gallery{
    .gallery-item{
      width:50%;
      p{
        font-size:1rem;
        line-height:1.1rem;
      }
    }
  }
  .prompt{
    margin-bottom:0;
    input{
      margin:10px 0;
    }
  }
  .gameSearch{
    ul{
      padding-left:0;
      li{
        padding-bottom:10px;
      }
    }
  }
  .mainContent{
    h1{
      font-size:2rem;
      line-height:2rem;
      overflow: initial;
      margin-bottom:10px;
    }
    .links{
      a{
        font-size:1.4rem;
      }
    }
    .spotify-player{
      margin:10px 0;
    }
  }
  .suggestion-box{
    h2{
      margin-top:0;
      font-size:1.5rem;
    }
    .submit-button{
      padding:10px;
      margin-top:10px;
    }
  }
}

.mobile-nav-links{
  display: flex;
  pointer-events: none;
  opacity:0;
  transition:opacity .3s ease;
  position:fixed;
  height:100%;
  width:100%;
  left:0; right:0;
  bottom:0; top:0;
  z-index:100;
  flex-direction:column;
  padding-top:100px;
  align-items:center;
  background-color:white;
  background: linear-gradient(-45deg, #fcd040, #ffffff);
	background-size: 400% 400%;
  a{
    color:#3a3a3a;
    margin-right:0;
    margin-bottom:20px;
    font-size:2rem;
  }
}

.VGP-logo h1{
  transition:color .3s ease;
  z-index:101;
  position:relative
}

.VGP-logo.open h1{
  color:#fcd040
}

.mobile-nav-links.open{
  pointer-events: all;
  opacity:1;
  
}

 .mobile-nav span
 {
   display: block;
   width: 33px;
   height: 4px;
   margin-bottom: 5px;
   position: relative;
   background: white;
   border-radius: 3px;
   z-index: 1;
   transform-origin: 4px 0px;   
   transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
               background 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
               opacity 0.55s ease;
 }
 
 .mobile-nav span:first-child
 {
   transform-origin: 0% 0%;
 }
 
 .mobile-nav span:nth-last-child(2)
 {
   transform-origin: 0% 100%;
 }
 
 .mobile-nav.open span
 {
   opacity: 1;
   background:#fcd040;
   z-index: 101;
   transform: rotate(45deg) translate(-5px, -15px);

 }
 
 .mobile-nav.open span:nth-last-child(3)
 {
   opacity: 0;
   transform: rotate(0deg) scale(0.2, 0.2);
 }
 
 .mobile-nav.open span:nth-last-child(2)
 {
   transform: rotate(-45deg) translate(-4px, 16px);
 }